




















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsBeneficiariesViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-beneficiaries-view-model';

@Component({
  name: 'ContractSavingsBeneficiaries',
  components: {
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsBeneficiaries extends Vue {
  contract_savings_beneficiaries_view_model = Vue.observable(
    new ContractSavingsBeneficiariesViewModel(this),
  );

  @Watch('contract_savings_beneficiaries_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async created() {
    await this.contract_savings_beneficiaries_view_model.initialize(getScreenSizeVariant());
  }
}

